(() => {
  angular
    .module('app.vehicles')
    .service('app.vehicles.stops.reportDataBuilder', reportDataBuilder);

  reportDataBuilder.$inject = [
    '$translate',
    'routeItemUtils',
    'app.reports.utils',
    'fuel-consumption-metering-settings',
    'app.vehicles.details.lib.fuel-calculations.fuel-calculations',
    'app.vehicles.details.lib.period-report-builder'
  ];

  function reportDataBuilder($translate, routeItemUtils, reportUtils,
    fuelConsumptionMeteringSettings, fuelCalculations, periodReportBuilder) {
    return {
      createFileName,
      getPageHeaderData,
      getMainTableHeaderData,
      getMainTableBodyData,
      getMainTableFooterData,
      formatDateForPrint,
      formatDurationInSeconds
    };

    function createFileName(data) {
      const startDate = new Date(parseInt(data.route.stops[0].timestampFrom, 10) * 1000);

      return [
        data.vehicleNumber.split('.').join(' '),
        'stops',
        formatDate(startDate)
      ].join('_');
    }

    function getPageHeaderData(data) {
      const result = {};

      result.vehicle = {
        id: data.id,
        number: data.vehicleNumber
      };

      const routeDetails = data.route;

      const date = new Date(parseInt(routeDetails.stops[0].timestampFrom, 10) * 1000);

      let totalDistance = routeItemUtils.getRouteTotalDistance(routeDetails, false);
      let totalFuelUsage = fuelCalculations.getDetailsForTimePeriod(routeDetails).totalFuelUsageInAllTanks;
      const averageFuelUsagePerKm = routeItemUtils.toNumber(totalFuelUsage / totalDistance);

      totalDistance = Math.round(totalDistance * 100) / 100;
      totalFuelUsage = Math.round(totalFuelUsage * 100) / 100;
      const averageFuelUsage = Math.floor(averageFuelUsagePerKm * 100 * 100) / 100;

      result.report = {
        date,
        isTradingMan: routeItemUtils.isTradingMan(routeDetails),
        totalMileage: totalDistance,
        totalFuelUsage,
        averageFuelUsage,
        maxSpeed: routeItemUtils.getRouteMaxSpeed(data.route),
        totalEngineWork: routeItemUtils.getRouteEngineOperationDuration(routeDetails)
      };

      const fuelConsumptionMeter = reportUtils.getFuelConsumptionMeterDetails(routeDetails);
      result.report.shouldShowFuelConsumptionMeterType = fuelConsumptionMeter.shouldShowFuelConsumptionMeterType;
      result.report.fuelConsumptionMeterTypeI18nKey = fuelConsumptionMeter.fuelConsumptionMeterTypeI18nKey;

      return result;
    }

    function getMainTableHeaderData(data) {
      return {
        hasTwoTanks:
          routeItemUtils.isAnyItemHasMainTankFuelData(data.route.stops)
          &&
          routeItemUtils.isAnyItemHasSecondTankFuelData(data.route.stops)
      };
    }

    function getMainTableBodyData(data, isForWebUI = false) {
      const routeDetails = data.route;

      const hasMainTankFuelData = routeItemUtils.isAnyItemHasMainTankFuelData(routeDetails.stops);
      const hasSecondTankFuelData = routeItemUtils.isAnyItemHasSecondTankFuelData(routeDetails.stops);
      const hasTwoTanks = hasMainTankFuelData && hasSecondTankFuelData;

      const isWeekend = (date) => {
        const weekDay = date.getDay();
        return weekDay === 0 || weekDay === 6;
      };

      const items = generateRouteData(data, isForWebUI);

      return items
        .map((routeItem) => {
          const result = {
            rowData: [
              routeItem.timeFrom,
              routeItem.timeTo,
              reportUtils.formatDurationInSeconds(routeItem.parking.duration),
              reportUtils.formatDurationInSeconds(routeItem.onWay.duration),
              Math.ceil(routeItem.distance * 10) / 10,
              routeItem.maxSpeed
            ],
            isWeekend: isWeekend(routeItem.date)
          };

          if (hasTwoTanks) {
            result.rowData.push(routeItem.fuelLevel.final.mainTank);
            result.rowData.push(routeItem.fuelLevel.final.secondTank);
          }

          let totalFuelUsage = Math.round(routeItem.totalFuelUsage * 100) / 100;
          if (!routeItemUtils.isImpulseSensorUsed(routeItem) && !routeItemUtils.isFuelCalculatorUsed(routeItem)) {
            totalFuelUsage = -totalFuelUsage
          }
          result.rowData.push(totalFuelUsage);

          result.rowData.push(getDetailsCellValue(
            routeItem,
            {
              shouldShowCombinedFuelTanks: data.shouldShowCombinedFuelTanks,
              hasTwoTanks,
              addressProvider: data.addressProvider,
              shouldShowDetailedView: data.shouldShowDetailedView
            }));

          return result;
        });
    }

    function getMainTableFooterData(data) {
      const headerData = getMainTableHeaderData(data);
      const routeDetails = data.route;

      const totalParkingDuration = routeItemUtils.getRouteTotalParkingDuration(routeDetails);

      const totalOnWayDuration = routeItemUtils.getRouteTotalMovementDuration(routeDetails);

      const totalDistance = Math.round(routeItemUtils.getRouteTotalDistance(routeDetails, false) * 10) / 10;
      let totalFuelUsage = fuelCalculations.getDetailsForTimePeriod(routeDetails).totalFuelUsageInAllTanks;
      totalFuelUsage = Math.round(totalFuelUsage * 100) / 100;

      return {
        hasTwoTanks: headerData.hasTwoTanks,
        totalParkingDuration,
        totalOnWayDuration,
        totalDistance,
        totalFuelUsage
      };
    }

    function formatDate(date) {
      return [
        `${date.getDate()}`.padStart(2, '0'),
        `${date.getMonth() + 1}`.padStart(2, '0'),
        date.getFullYear()
      ].join('-');
    }

    function formatDateForPrint(date) {
      return [
        `${date.getDate()}`.padStart(2, '0'),
        `${date.getMonth() + 1}`.padStart(2, '0'),
        `${date.getFullYear()}`
      ].join('.');
    }

    function formatDurationInSeconds(seconds) {
      const minutes = Math.floor(seconds / 60);
      if (minutes < 60) {
        return `${minutes} ${$translate.instant('global.minutes')}`;
      }

      const hours = `${Math.floor(minutes / 60)}`.padStart(2, '0');
      let extraMinutes = minutes - hours * 60;
      if (extraMinutes === 0) {
        return `${hours} ${$translate.instant('global.hours')}`;
      }

      extraMinutes = `${extraMinutes}`.padStart(2, '0');
      return `${hours}:${extraMinutes} ${$translate.instant('global.hours')}`;
    }

    function generateRouteData(data, isForWebUI) {
      if (isForWebUI) {
        return getRouteItemsForDetailedView(data);
      }
      return periodReportBuilder.create(data);
    }

    function formatDuration(date) {
      return [
        `${date.getHours()}`.padStart(2, '0'),
        `${date.getMinutes()}`.padStart(2, '0'),
        `${date.getSeconds()}`.padStart(2, '0')
      ].join(':');
    }

    // XXX it's for backward compatibility. There should be a better approach
    function getRouteItemsForDetailedView(data) {
      const stops = data.route.stops;

      const routeSummaryDetails = data.route.summary;

      const items = [];

      stops.forEach((rawRouteItem, index) => {
        const startDate = new Date(rawRouteItem.timestampFrom * 1000);
        const endDate = new Date(rawRouteItem.timestampTo * 1000);

        if (routeItemUtils.isRouteItemWithNoGPS(rawRouteItem)) {
          items.push({
            status: rawRouteItem.status,
            date: startDate,
            timestampFrom: rawRouteItem.timestampFrom,
            timestampTo: rawRouteItem.timestampTo,
            latitude: rawRouteItem.latitude,
            longitude: rawRouteItem.longitude,
            timeFrom: formatDuration(startDate),
            timeTo: formatDuration(endDate),
            onWay: {
              from: 0,
              to: 0,
              duration: 0
            },
            parking: {
              from: 0,
              to: 0,
              duration: 0
            },
            distance: 0,
            maxSpeed: 0,
            fuelLevel: {
              initial: {
                mainTank: 0,
                secondTank: 0
              },
              final: {
                mainTank: 0,
                secondTank: 0
              }
            },
            fuelUsage: {},
            mainTankFuelUsage: 0,
            secondTankFuelUsage: 0,
            totalFuelUsage: 0,
            isFuelCalculatorUsed: false,
            hasRefueling: false,
            hasFuelDrain: false,
            hasNormalFuelConsumption: false,
            hasUnchangedFuelAmount: false,
            hasExceededDowntime: false,
            hasNoGPS: true,
            engineOperationTime: 0,
            addresses: {
              google: rawRouteItem.addressGoogle,
              yandex: rawRouteItem.addressYandex
            },
            routeSummaryDetails
          });
          return;
        }

        if (!data.shouldShowDetailedView && !routeItemUtils.isRouteItemWithParking(rawRouteItem)) {
          return;
        }

        const previousRawRouteItem = index > 0 ? stops[index - 1] : null;

        const isExcludedRouteItem = (routeItem) =>
          fuelConsumptionMeteringSettings.isExcludedEventWithControversialFuelLevel(routeItem);

        const fuelUsageDetails =
          routeItemUtils.getFuelUsageDetails(rawRouteItem, previousRawRouteItem,
            routeSummaryDetails, data.shouldShowDetailedView, isExcludedRouteItem);

        const isRouteItemWithParking = routeItemUtils.isRouteItemWithParking(rawRouteItem);
        const mainTankFuelUsage = isRouteItemWithParking ? 0 : fuelUsageDetails.mainTank;
        const secondTankFuelUsage = isRouteItemWithParking ? 0 : fuelUsageDetails.secondTank;
        const totalFuelUsage = isRouteItemWithParking ? 0 : fuelUsageDetails.total;

        items.push({
          status: rawRouteItem.status,
          date: startDate,
          timestampFrom: rawRouteItem.timestampFrom,
          timestampTo: rawRouteItem.timestampTo,
          latitude: rawRouteItem.latitude,
          longitude: rawRouteItem.longitude,
          timeFrom: formatDuration(startDate),
          timeTo: formatDuration(endDate),
          onWay: routeItemUtils.getOnWayDetails(rawRouteItem, previousRawRouteItem, {
            shouldUsePreviousRouteItemData: !data.shouldShowDetailedView
          }),
          parking: routeItemUtils.getParkingDetails(rawRouteItem),
          distance: routeItemUtils.getDistance(rawRouteItem, previousRawRouteItem, data.shouldShowDetailedView),
          maxSpeed: routeItemUtils.getMaxSpeed(rawRouteItem, previousRawRouteItem, data.shouldShowDetailedView),
          fuelLevel: routeItemUtils.getFuelLevelDetails(rawRouteItem),
          fuelUsage: {},
          mainTankFuelUsage,
          secondTankFuelUsage,
          totalFuelUsage,
          isFuelCalculatorUsed: fuelUsageDetails.isFuelCalculatorUsed,
          hasRefueling: routeItemUtils.hasRefueling(rawRouteItem),
          hasFuelDrain: routeItemUtils.hasFuelDrain(rawRouteItem, {
            idle: (data.route.summary.fuelSettings || {}).idle
          }),
          hasNormalFuelConsumption: routeItemUtils.hasNormalFuelConsumption(rawRouteItem, {
            idle: (data.route.summary.fuelSettings || {}).idle
          }),
          hasUnchangedFuelAmount: routeItemUtils.hasUnchangedFuelAmount(rawRouteItem),
          hasExceededDowntime: routeItemUtils.hasExceededDowntime(rawRouteItem),
          engineOperationTime: routeItemUtils.getEngineOperationTime(rawRouteItem),
          addresses: {
            google: rawRouteItem.addressGoogle,
            yandex: rawRouteItem.addressYandex
          },
          routeSummaryDetails
        });
      });

      return items;
    }

    // ======================
    // TODO move the code bellow into common utils
    // ======================
    function getDetailsCellValue(routeItem, params) {
      if (routeItem.hasNoGPS) {
        return 'No GPS signal';
      }

      let parts = [];

      const mileageDetailsStr = routeItemUtils.getMileageDetailsStr(routeItem);
      if (mileageDetailsStr.length > 0) {
        // parts.push({
        //   text: mileageDetailsStr
        // });
      }

      const maxSpeedDetailsStr = routeItemUtils.getMaxSpeedDetails(routeItem);
      if (maxSpeedDetailsStr.length > 0) {
        // parts.push({
        //   text: maxSpeedDetailsStr
        // });
      }

      const fuelDetailsStr = getFuelDetailsStr(routeItem, params);
      if (fuelDetailsStr.length > 0) {
        // parts.push({
        //   text: fuelDetailsStr
        // });
      }

      const locationDetailsStr = routeItemUtils.getLocationDetailsStr(routeItem, params);
      if (typeof locationDetailsStr === 'string' && locationDetailsStr.length > 0) {
        const noGpsSubString = '<s>GPS</s>';
        const noGpsSubStringStartIndex = locationDetailsStr.indexOf(noGpsSubString);
        if (noGpsSubStringStartIndex > -1) {
          parts.push([
            {
              text: locationDetailsStr.substring(0, noGpsSubStringStartIndex)
            },
            {
              text: locationDetailsStr
                .substring(noGpsSubStringStartIndex, noGpsSubString.length)
                .replace('<s>', '')
                .replace('</s>', ''),

              // for XLSX
              font: {
                strike: true
              },

              // for PDF
              decoration: 'lineThrough'
            },
            {
              text: locationDetailsStr.substring(noGpsSubStringStartIndex + noGpsSubString.length)
            }
          ]);
        } else {
          parts.push({
            text: locationDetailsStr
          });
        }
      }

      const engineOperationDetailsStr = routeItemUtils.getEngineOperationDetailsStr(routeItem);
      if (engineOperationDetailsStr.length > 0) {
        parts.push({
          text: `+${engineOperationDetailsStr}`
        });
      }

      const temperatureDetailsStr = routeItemUtils.getTemperatureDetailsStr(routeItem);
      if (temperatureDetailsStr.length > 0) {
        parts.push({
          text: temperatureDetailsStr
        });
      }

      return {
        richText: parts.reduce((acc, obj, index) => {
          if (Array.isArray(obj)) {
            acc = acc.concat(obj);
          } else {
            acc.push(obj);
          }

          if (index !== parts.length - 1) {
            acc.push({
              text: ', '
            });
          }

          return acc;
        }, [])
      };
    }

    function getFuelDetailsStr(currentRouteItem, params) {
      const paramsForSubFunctions = Object.assign(
        {},
        params,
        {
          separatorStr: '>>'
        }
      );

      switch (true) {
        case currentRouteItem.hasRefueling:
          return routeItemUtils.getFuelDetailsForRefueling(currentRouteItem, paramsForSubFunctions);
        case currentRouteItem.hasFuelDrain:
          return routeItemUtils.getFuelDetailsStrForFuelDraining(currentRouteItem, paramsForSubFunctions);
        case currentRouteItem.hasNormalFuelConsumption:
          return routeItemUtils.getFuelDetailsStrForNormalFuelConsumption(currentRouteItem, paramsForSubFunctions);
        default:
          return routeItemUtils.getFuelDetailsStrForUnchangedFuelAmount(currentRouteItem, paramsForSubFunctions);
      }
    }
  }
})();
